import NextLink from "next/link";
import defaultElements from "@wuilt/section-elements/build/defaultElements";
import { Provider as SectionElementsProvider } from "@wuilt/section-elements/build/elementsContext";
import Section from "./Section";
import Image, { imageLoader } from "./Image";
import Head from "next/head";
import { NextRouter, useRouter as useNextRouter } from "next/router";
import { useEffect } from "react";
import { Loader } from "./Loader";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Animation,
  CommonPagesType,
  Locale,
  Page,
  ThemeContent,
  UniversalData,
  UniversalSections,
} from "types";
import getConfig from "next/config";
import GlobalNavbar from "./GlobalNavbar";

const { publicRuntimeConfig } = getConfig();

const BASE_API_URL =
  publicRuntimeConfig?.BASE_API_URL || "https://api.wuilt.com";

type WebsitePage = CommonPagesType & {
  locale: Locale;
  path: string;
  sort_order: number;
};

interface SiteProps {
  websiteId: string;
  page: Page;
  universalData: UniversalData;
  universalSections: UniversalSections;
  currentLocale: Locale;
  defaultLocale: Locale;
  pages: (CommonPagesType & {
    locale: Locale;
    path: string;
    sort_order: number;
  })[];
  children: React.ReactNode;
  animation: Animation;
  isUnderMaintenance?: any;
  theme: ThemeContent;
}

function Link(props) {
  return (
    <NextLink className={props.className} {...props} href={props.href || "#"}>
      {props.children}
    </NextLink>
  );
}

function createPageLink(pages: WebsitePage[]) {
  function PageLink({ page: providedPage, ...props }) {
    if (!providedPage) return null;
    const page = pages.find((page) => page.id == providedPage.id);
    if (!page) return null;
    return (
      <NextLink {...props} href={`/${page.path}`}>
        {props.children}
      </NextLink>
    );
  }
  return PageLink;
}

function createPageRouter(pages: WebsitePage[], nextRouter: NextRouter) {
  function useRouter() {
    return {
      ...nextRouter,
      pushPage: (pageId: string, target = "_self") => {
        const page = pages.find((page) => page.id == pageId);
        if (!page) return;
        if (target === "_self") {
          nextRouter.push(`/${page?.path}`);
        } else {
          window.open(`/${page?.path}`, target);
        }
      },
      replacePage: (pageId: string, target = "_self") => {
        const page = pages.find((page) => page.id == pageId);
        if (!page) return;
        if (target === "_self") {
          nextRouter.replace(`/${page?.path}`);
        } else {
          window.open(`/${page?.path}`, target);
        }
      },
    };
  }
  return useRouter;
}

function WebsiteProvider(props: SiteProps) {
  const {
    websiteId,
    universalData,
    universalSections,
    currentLocale,
    pages,
    children,
    animation,
    isUnderMaintenance,
    theme,
  } = props;

  useEffect(() => {
    AOS.init();
    let scrollRef = 0;

    window.addEventListener("scroll", function () {
      // increase value up to 10, then refresh AOS
      scrollRef <= 10 ? scrollRef++ : AOS.refresh();
    });
  }, []);

  useEffect(() => {
    document.body.classList.remove("rtl", "ltr");
    document.body.classList.add(currentLocale === "ar" ? "rtl" : "ltr");
    const root = document.getElementsByTagName("html")[0];
    root.lang = currentLocale;
    root.className = currentLocale;
  }, [currentLocale]);

  const nextRouter = useNextRouter();
  const PageLink = createPageLink(pages);
  const useRouter = createPageRouter(pages, nextRouter);

  const sectionsElementsValue = {
    elements: defaultElements,
    base: { Image, Link, imageLoader, Head, PageLink, useRouter },
    props: {
      uploadFileUrl: (formId: string, sectionId: string) =>
        `${BASE_API_URL}/${sectionId}/${formId}/upload-file-v2`,
      getFormSubmitUrl: (formId: string) =>
        `${BASE_API_URL}/${formId}/submitv2`,
      createFormUrl: `${BASE_API_URL}/${websiteId}/create-form-v2`,
      siteId: websiteId,
      baseApiUrl: BASE_API_URL,
      theme,
    },
    mode: "production",
  };

  return (
    // @ts-ignore
    <SectionElementsProvider value={sectionsElementsValue}>
      <Loader />
      {!isUnderMaintenance && (
        <GlobalNavbar
          locale={currentLocale}
          universalData={universalData}
          universalSections={universalSections}
        />
      )}
      <div id="overflow-hidden">
        {children}
        {!isUnderMaintenance && (
          <Section
            id={universalSections.footer[currentLocale].id}
            design={universalSections.footer[currentLocale].section_design}
            values={universalSections.footer[currentLocale].section_value}
            universal={universalData}
            animation={theme ? theme.animation : animation}
          />
        )}
      </div>
    </SectionElementsProvider>
  );
}

export default WebsiteProvider;
